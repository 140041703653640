import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Category } from '../interfaces/category';
import { environment } from '../../enviroments/environment';
import { BehaviorSubject, lastValueFrom } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class CategoryService {
  categoriesWithChildren: BehaviorSubject<Category[]> = new BehaviorSubject<
    Category[]
  >([]);
  categoriesWithChildrenObserve = this.categoriesWithChildren.asObservable();
  constructor(private http: HttpClient) {}

  async getCategories() {
    const response = await lastValueFrom(
      this.http.get<Category[]>(
        `${environment.appConfig.apiEndpoint}/categories`
      )
    );

    let categories: Category[] = [];

    for (const cat of response) {
      if (cat.parent == null || cat.parent === 0) {
        let children = await lastValueFrom(
          this.http.get<Category[]>(
            `${environment.appConfig.apiEndpoint}/categories/childs?category=${cat.id}`
          )
        );
        if (children.length > 0) {
          await Promise.all(
            children.map(async (child) => {
              if (child.filterable) {
                const grandchildren = await lastValueFrom(
                  this.http.get<Category[]>(
                    `${environment.appConfig.apiEndpoint}/categories/childs?category=${child.id}`
                  )
                );
                child.children = grandchildren;
              }
            })
          );
          cat.children = children;
        }
        categories.push(cat);
      }
    }
    const categoriesToFilter = structuredClone(categories);
    this.categoriesWithChildren.next(
      categoriesToFilter
        .filter(
          (cat) =>
            cat.children !== undefined &&
            cat.children?.some((child) => child.filterable)
        )
        .map((cat) => {
          cat.children = cat.children?.filter((child) => child.filterable);
          return cat.children;
        })
        .flat(1) as Category[]
    );
    return categories;
  }
}
