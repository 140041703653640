import { Component, Inject, OnInit } from '@angular/core';
import {
  MSAL_GUARD_CONFIG,
  MsalGuardConfiguration,
  MsalService,
} from '@azure/msal-angular';

import { RedirectRequest } from '@azure/msal-browser';
import { ClearAppStoragesService } from '../../services/clearAppStorages.service';

@Component({
  selector: 'app-login',
  standalone: true,
  imports: [],
  templateUrl: './login.component.html',
  styleUrl: './login.component.css',
})
export class LoginComponent implements OnInit {
  constructor(
    @Inject(MSAL_GUARD_CONFIG) private msalGuardConfig: MsalGuardConfiguration,
    private authService: MsalService,
    private clearCacheService: ClearAppStoragesService
  ) {}

  ngOnInit(): void {}

  loginRedirect() {
    if (this.msalGuardConfig.authRequest) {
      const account = this.authService.loginRedirect({
        ...this.msalGuardConfig.authRequest,
      } as RedirectRequest);
      console.log('Account', account);
    } else {
      this.authService.loginRedirect();
    }
  }
  async clearLocalData() {
    await this.clearCacheService.clearCacheStorage();
    this.clearCacheService.clearLocalStorage();
    this.clearCacheService.clearSessionStorage();
  }
  login() {
    this.loginRedirect();
  }
}
