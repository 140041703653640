import { Asset } from '../interfaces/asset';

/**
 * function to convert MS to Min:SS
 * @param milliseconds Time passed in MS
 * @returns
 */
export const millisecondsToMinutesSeconds = (milliseconds: number) => {
  const totalSeconds = Math.round(milliseconds / 1000);
  const minutes = Math.round(totalSeconds / 60);
  const seconds = totalSeconds % 60;

  return `${minutes}m ${seconds}s`;
};

/**
 *
 * @param originalArray the array before modification
 * @param updatedArray  the array contains possible new data
 * @returns either the original Array or one modificated with new data
 */
export const synchronizeData = (
  originalArray: Asset[],
  updatedArray: Asset[]
): unknown[] => {
  let originalStringArray = originalArray.map((e) => JSON.stringify(e));
  let updatedStringArray = updatedArray.map((e) => JSON.stringify(e));
  let filteredData: Array<Asset> = [];
  const originalSet = new Set(originalStringArray);
  const updatedSet = new Set(updatedStringArray);
  const remoteToLocal = updatedArray.filter((item) => {
    return !originalSet.has(JSON.stringify(item));
  });
  const LocalToRemote = originalArray.filter((item) => {
    return !updatedSet.has(JSON.stringify(item));
  });
  if (LocalToRemote.length > 0) {
    filteredData = originalArray.filter((o) => !LocalToRemote.includes(o));
  }
  if (remoteToLocal.length > 0) {
    originalArray.push(...remoteToLocal);
    filteredData = originalArray;
  }
  return filteredData;
};
export const isDateOlderThanLimit = (dateString: string, limit: number) => {
  const dateToCheck = new Date(dateString).getTime();
  const today = Date.now();
  const diffInDays = (today - dateToCheck) / (1000 * 60 * 60 * 24);
  return diffInDays > limit;
};
export function compareCommonPropertyValues(
  obj1: Record<string, unknown> | null,
  obj2: Record<string, unknown> | null
): boolean {
  // Basic validation: Ensure both inputs are actual objects (not null, arrays, etc.)
  // Type guards help TypeScript narrow down the types after this block.
  if (
    typeof obj1 !== 'object' ||
    obj1 === null ||
    Array.isArray(obj1) ||
    typeof obj2 !== 'object' ||
    obj2 === null ||
    Array.isArray(obj2)
  ) {
    console.warn('Both inputs must be plain objects.');
    return false;
  }
  // At this point, TypeScript knows obj1 and obj2 are non-null objects

  // Get keys from the first object
  const keys1: string[] = Object.keys(obj1);

  // Iterate through keys of the first object
  for (const key of keys1) {
    // Check if the key also exists in the second object
    if (Object.prototype.hasOwnProperty.call(obj2, key)) {
      // If the key exists in both, compare their values using strict equality (===)
      // Accessing values using obj1[key] and obj2[key] is safe here.
      if (obj1[key] !== obj2[key]) {
        // If any common key has different values, return false immediately
        return false;
      }
    }
    // If key only exists in obj1, ignore it (as per the requirement)
  }

  // If the loop completes without finding any mismatch among common keys, return true.
  // Keys present only in obj2 are implicitly ignored because we only iterate over obj1's keys.
  return true;
}
