import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class ClearAppStoragesService {
  /**
   * Clears all caches managed by the Cache Storage API.
   * Often used by Service Workers.
   * @returns {Promise<void>} A promise that resolves when all caches are deleted.
   */
  async clearCacheStorage(): Promise<void> {
    if ('caches' in window) {
      try {
        const cacheNames = await window.caches.keys();
        if (cacheNames.length > 0) {
          console.log('Clearing Cache Storage entries:', cacheNames);
          await Promise.all(
            cacheNames.map((cacheName) => window.caches.delete(cacheName))
          );
          console.log('Cache Storage cleared successfully.');
        } else {
          console.log('No Cache Storage entries found to clear.');
        }
      } catch (error) {
        console.error('Error clearing Cache Storage:', error);
      }
    } else {
      console.warn('Cache Storage API not supported in this browser.');
    }
  }

  /**
   * Clears all data from localStorage for the current origin.
   */
  clearLocalStorage(): void {
    if ('localStorage' in window) {
      try {
        const itemCount = window.localStorage.length;
        if (itemCount > 0) {
          console.log(`Clearing ${itemCount} localStorage item(s).`);
          window.localStorage.clear();
          console.log('localStorage cleared successfully.');
        } else {
          console.log('localStorage is already empty.');
        }
      } catch (error) {
        console.error('Error clearing localStorage:', error);
      }
    } else {
      console.warn('localStorage not supported in this browser.');
    }
  }

  /**
   * Clears all data from sessionStorage for the current origin and session.
   */
  clearSessionStorage(): void {
    if ('sessionStorage' in window) {
      try {
        const itemCount = window.sessionStorage.length;
        if (itemCount > 0) {
          console.log(`Clearing ${itemCount} sessionStorage item(s).`);
          window.sessionStorage.clear();
          console.log('sessionStorage cleared successfully.');
        } else {
          console.log('sessionStorage is already empty.');
        }
      } catch (error) {
        console.error('Error clearing sessionStorage:', error);
      }
    } else {
      console.warn('sessionStorage not supported in this browser.');
    }
  }

  /**
   * Unregisters all active Service Workers for the current origin.
   * This prevents them from serving cached content on subsequent loads.
   * @returns {Promise<void>} A promise that resolves when all service workers are unregistered.
   */
  async unregisterServiceWorkers(): Promise<void> {
    if ('serviceWorker' in navigator) {
      try {
        const registrations = await navigator.serviceWorker.getRegistrations();
        if (registrations.length > 0) {
          console.log('Unregistering Service Workers:', registrations);
          const unregisterPromises = registrations.map((registration) =>
            registration.unregister()
          );
          await Promise.all(unregisterPromises);
          console.log('Service Workers unregistered successfully.');
        } else {
          console.log('No active Service Workers found to unregister.');
        }
      } catch (error) {
        console.error('Error unregistering Service Workers:', error);
      }
    } else {
      console.warn('Service Workers not supported in this browser.');
    }
  }
}
