<div class="main-container">
  <header class="header-6">
    <div class="branding">
      <span>Powerslide</span>&nbsp;<span style="font-weight: 200">3.0</span>
    </div>

    <div class="settings" *ngIf="loginDisplay">
      <clr-toggle-container clrInline class="toggle-container">
        <clr-toggle-wrapper>
          <input
            (change)="toggleAutoUpdate()"
            [checked]="autoUpdateOn"
            type="checkbox"
            clrToggle
            name="options"
            value="{{ autoUpdateOn }}"
          />
          <label class="update-label">Automatische-Synchronisation</label>
        </clr-toggle-wrapper>
      </clr-toggle-container>
      <clr-dropdown>
        <a class="nav-icon" clrDropdownTrigger>
          <cds-icon shape="cog"></cds-icon>
        </a>
        <clr-dropdown-menu *clrIfOpen clrPosition="bottom-right">
          <a href="#" routerLink="/app/about" clrDropdownItem>Über die App</a>
          <a
            href="#"
            routerLink="/login"
            clrDropdownItem
            (click)="logout(false)"
            >Abmelden</a
          >
        </clr-dropdown-menu>
      </clr-dropdown>
    </div>
  </header>
  <div class="content-container">
    <clr-vertical-nav
      [clrVerticalNavCollapsible]="true"
      [(clrVerticalNavCollapsed)]="isCollapsed"
      (click)="callCollapse($event)"
      class="vertical-nav"
      style="
        padding: 0.3rem;
        z-index: 2;
        scroll-padding-right: 10rem;
        overflow-y: hidden !important;
      "
      *ngIf="loginDisplay"
    >
      <div [(class)]="containerClass">
        <div *ngFor="let category of categories">
          <!-- next <a *ngIf determine if main category , so parent, has filterable flag set -->
          <!-- If this set means category has alot of assets saved and should appears as main category which assets handeled differently -->
          <a
            *ngIf="!category.children"
            clrVerticalNavLink
            [routerLink]="
              updatingDbOngoing
                ? null
                : 'app/assets/' + category.name + '/' + category.id
            "
            routerLinkActive="active"
            [class]="updatingDbOngoing ? 'disabled-link' : ''"
          >
            <span class="material-symbols-outlined" clrVerticalNavIcon>{{
              category.icon
            }}</span>
            {{ category.name }}</a
          >
          <clr-vertical-nav-group
            *ngIf="category.children"
            routerLinkActive="active"
            [class]="updatingDbOngoing ? 'disabled-link' : ''"
          >
            <span class="material-symbols-outlined" clrVerticalNavIcon>{{
              category.icon
            }}</span>
            {{ category.name }}
            <clr-vertical-nav-group-children>
              <a
                *ngFor="let child of category.children"
                clrVerticalNavLink
                [routerLink]="
                  updatingDbOngoing
                    ? null
                    : 'app/assets/' + category.name + '/' + child.id
                "
                [class]="updatingDbOngoing ? 'disabled-link' : ''"
                routerLinkActive="active"
                >{{ child.name }}</a
              >
            </clr-vertical-nav-group-children>
          </clr-vertical-nav-group>
        </div>
      </div>
    </clr-vertical-nav>
    <div class="content-area">
      <div class="outlet-container">
        <router-outlet></router-outlet>
      </div>
      <div class="footer-toolbar">
        <div class="state-element" *ngIf="isOnline">
          <p><span class="led-badge green"></span>Online</p>
        </div>
        <div class="state-element" *ngIf="!isOnline">
          <p><span class="led-badge red"></span>Offline</p>
        </div>
        <div class="state-element" style="align-self: center" *ngIf="isLoading">
          <span class="spinner spinner-sm">Daten werden geladen...</span>
          <p style="margin-left: 1rem">
            {{ loadingMessage }}
          </p>
        </div>
      </div>
    </div>
  </div>
</div>
