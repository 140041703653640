import { Injectable } from '@angular/core';
import { Asset } from '../interfaces/asset';
import { Category } from '../interfaces/category';

import { LoaderService, LoaderState } from './loader.service';
import { BehaviorSubject, Observable } from 'rxjs';
import { compareCommonPropertyValues } from './useables';

export interface TransactionInfo {
  transaction: IDBTransaction;
  description?: string;
  startTime: number;
  categoryNumber?: number;
}
export interface DbInitializationStatus {
  ready: 'READY' | 'NOT_READY' | 'DELETED';
  error: Error | null;
  message?: string; // Optional message
}
@Injectable({
  providedIn: 'root',
})
/**
 * Service to handle all the database operations
 * When Schema is updated, the version number should be increased
 */
export class DatabaseService {
  private db!: IDBDatabase | null | undefined;
  private dbReadyPromise: Promise<boolean> | null = null;
  private readonly dbName = 'assets_db';
  //IMPORTANT: Update this number when the schema is updated
  //So If New ObjectStores are added, or existing ones are updated
  private currentDBVersion = 2;
  constructor(private loaderService: LoaderService) {}
  private dbStatusSubject = new BehaviorSubject<DbInitializationStatus>({
    ready: 'NOT_READY', // Initial state: not ready
    error: null,
    message: 'Initializing...',
  });
  public dbStatus$: Observable<DbInitializationStatus> =
    this.dbStatusSubject.asObservable();
  // private async openDatabase(): Promise<void> {
  //   const request = window.indexedDB.open('assets_db', this.currentDBVersion);

  //   request.onerror = (event) => {
  //     const request = event.target as IDBOpenDBRequest;
  //     console.error('Error opening database:', request.error);
  //   };

  //   request.onupgradeneeded = (event: IDBVersionChangeEvent) => {
  //     this.db = (event.target as IDBOpenDBRequest).result;
  //     if (event.oldVersion === 0) {
  //       this.createObjectStores(this.db);
  //     } else {
  //       this.updateObjectStores(this.db);
  //     }
  //   };

  //   request.onsuccess = (event) => {
  //     this.db = (event.target as IDBOpenDBRequest).result;

  //     console.log('Database opened successfully');

  //     this.db.onversionchange = (event) => {
  //       console.warn(
  //         `Database version change requested (old: ${event.oldVersion}, new: ${event.newVersion}). Closing connection to allow change.`
  //       );
  //       if (this.db) {
  //         this.db.close(); // Close immediately
  //         // Optionally set this.db = null here too, or manage state appropriately
  //         console.log('Connection closed due to onversionchange.');
  //         // Consider notifying the user or reloading the app if necessary
  //         // alert("The application database needs to update. Please reload.");
  //       }
  //     };
  //   };
  //   request.onblocked = (event) => {
  //     console.warn(
  //       `Opening database "assets_db" is blocked. Close other connections.`
  //     );
  //   };
  // }
  initDb(): Promise<boolean> {
    if (this.dbReadyPromise) {
      return this.dbReadyPromise;
    }
    this.dbStatusSubject.next({
      ready: 'NOT_READY',
      error: null,
      message: 'Opening database...',
    });
    const loaderId = this.loaderService.show(
      { message: 'Datenbank wird initialisiert...' },
      LoaderState.LocalLoading
    );

    this.dbReadyPromise = new Promise<boolean>((resolve, reject) => {
      if (!window.indexedDB) {
        console.error('[DatabaseService] IndexedDB not supported.');
        this.loaderService.hide(loaderId);
        return reject(new Error('IndexedDB not supported'));
      }

      const request = window.indexedDB.open(this.dbName, this.currentDBVersion);

      request.onerror = (event) => {
        const error = (event.target as IDBOpenDBRequest).error;
        console.error('[DatabaseService] Error opening database:', error);
        this.loaderService.hide(loaderId);
        reject(error || new Error('Unknown error opening database'));
      };

      request.onupgradeneeded = (event: IDBVersionChangeEvent) => {
        const dbInstance = (event.target as IDBOpenDBRequest).result;
        if (event.oldVersion < 1) {
          this.createObjectStores(dbInstance);
        } else {
          this.updateObjectStores(dbInstance);
        }
      };

      request.onsuccess = (event) => {
        this.db = (event.target as IDBOpenDBRequest).result;

        this.addRuntimeDbListeners(this.db);
        this.loaderService.hide(loaderId);
        this.dbStatusSubject.next({
          ready: 'READY',
          error: null,
          message: 'Database ready.',
        });
        resolve(true);
      };

      request.onblocked = () => {
        console.warn(
          `[DatabaseService] Opening database "${this.dbName}" is blocked. Close other tabs/connections.`
        );
        this.loaderService.hide(loaderId);
        reject(new Error(`Opening database "${this.dbName}" was blocked.`));
      };
    });

    this.dbReadyPromise.catch((err) => {
      console.error(
        '[DatabaseService] Initialization via initDb() failed:',
        err
      );
      this.dbReadyPromise = null;
    });

    return this.dbReadyPromise;
  }
  private addRuntimeDbListeners(db: IDBDatabase): void {
    db.onversionchange = (event) => {
      console.warn(
        `[DatabaseService] onversionchange detected (old: ${event.oldVersion}, new: ${event.newVersion}). Closing connection.`
      );
      db.close();
      if (this.db === db) {
        this.db = null;
        this.dbReadyPromise = null;
        console.log(
          '[DatabaseService] Main DB connection closed due to version change elsewhere.'
        );
      }
    };
    db.onerror = (event) => {
      console.error(
        '[DatabaseService] Uncaught database error:',
        (event.target as IDBRequest)?.error
      );
    };
    db.onclose = () => {
      if (this.db === db) {
        console.log(
          `[DatabaseService] Main DB connection "${this.dbName}" closed.`
        );
        this.db = null;
        this.dbReadyPromise = null;
      } else {
        console.log(
          `[DatabaseService] A DB connection instance (not main) closed.`
        );
      }
    };
  }
  private updateObjectStores(db: IDBDatabase) {
    const objectStoreNames = Array.from(db.objectStoreNames);
    for (const storeName of objectStoreNames) {
      db.deleteObjectStore(storeName);
    }
    this.createObjectStores(db);
  }

  async getDatabase(): Promise<IDBDatabase> {
    if (this.db) {
      return this.db;
    }
    if (this.dbReadyPromise) {
      await this.dbReadyPromise;
      if (this.db) {
        return this.db;
      } else {
        throw new Error(
          'Database initialization completed but DB instance is still null.'
        );
      }
    }
    await this.initDb();
    if (this.db) {
      return this.db;
    } else {
      throw new Error(
        'Database initialization attempted by getDatabase failed.'
      );
    }
  }
  private createObjectStores(db: IDBDatabase) {
    const metaDbDataStore = db.createObjectStore('meta', {
      keyPath: 'id',
      autoIncrement: true,
    });
    metaDbDataStore.createIndex('created_at', 'created_at', {
      unique: true,
    });
    const initialMetaData: {
      createdAt: number;
    } = {
      createdAt: Date.now(),
    };
    metaDbDataStore.add(initialMetaData);

    const categorieStore = db.createObjectStore('categories', {
      keyPath: 'id',
      autoIncrement: true,
    });
    /**
     * categories Table
     */
    categorieStore.createIndex('name', 'name', { unique: false });
    categorieStore.createIndex('icon', 'icon', { unique: false });
    categorieStore.createIndex('visible', 'visible', { unique: false });
    categorieStore.createIndex('row_number', 'row_number', {
      unique: false,
    });
    categorieStore.createIndex('parent', 'parent', { unique: false });
    categorieStore.createIndex('filterable', 'filterable', { unique: false });

    const assetsStore = db.createObjectStore('assets', {
      keyPath: 'id',
      autoIncrement: true,
    });
    /**
     * Assets Table
     */
    assetsStore.createIndex('title', 'title', { unique: false });
    assetsStore.createIndex('description', 'description', { unique: false });
    assetsStore.createIndex('type', 'type', { unique: false });
    assetsStore.createIndex('encoded_data', 'encoded_data', {
      unique: false,
    });
    assetsStore.createIndex('thumbnail', 'thumbnail', { unique: false });
    assetsStore.createIndex('category', 'category', { unique: false });
    assetsStore.createIndex('childCategory', 'childCategory', {
      unique: false,
    });
    assetsStore.createIndex('category_childCategory', [
      'category',
      'childCategory',
    ]);
    assetsStore.createIndex('created_at', 'created_at', { unique: false });
    assetsStore.createIndex('to_delete', 'to_delete', { unique: false });
    const categortyUpdatedDate = db.createObjectStore('category_last_updated', {
      keyPath: 'id',
      autoIncrement: true,
    });
    categortyUpdatedDate.createIndex('last_updated', 'last_updated', {
      unique: false,
    });
    categortyUpdatedDate.createIndex('category_id', 'category_id', {
      unique: true,
    });
  }
  getMetaData(): Promise<{
    createdAt: number;
  }> {
    return new Promise<{
      createdAt: number;
    }>((resolve, reject) => {
      if (this.db) {
        const transaction = this.db.transaction(['meta'], 'readonly');
        const objectStore = transaction.objectStore('meta');
        const request = objectStore.get(1);
        request.onsuccess = () => {
          resolve(
            request.result as {
              createdAt: number;
            }
          );
        };
        request.onerror = () => {
          reject(
            new Error(`Failed to get metadata: ${request.error?.message}`)
          );
        };
      } else {
        reject(new Error('Database not available'));
      }
    });
  }
  setCreatedAt(createdAt: number): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      if (this.db) {
        const transaction = this.db.transaction(['meta'], 'readwrite');
        const objectStore = transaction.objectStore('meta');
        const request = objectStore.get(1); // Get the existing metadata

        request.onsuccess = () => {
          const existingMetaData = request.result as
            | { createdAt: number }
            | undefined;

          if (existingMetaData) {
            // Update the existing metadata with the new createdAt value
            existingMetaData.createdAt = createdAt;
            const updateRequest = objectStore.put(existingMetaData);

            updateRequest.onsuccess = () => resolve();
            updateRequest.onerror = () => {
              reject(
                new Error(
                  `Failed to update metadata: ${updateRequest.error?.message}`
                )
              );
            };
          } else {
            reject(new Error('Metadata not found'));
          }
        };

        request.onerror = () => {
          reject(
            new Error(`Failed to get metadata: ${request.error?.message}`)
          );
        };
      } else {
        reject(new Error('Database not available'));
      }
    });
  }
  getObjectStoreCount(db: IDBDatabase, storeName: string): Promise<number> {
    return new Promise((resolve, reject) => {
      const transaction = db.transaction(storeName, 'readonly');
      const objectStore = transaction.objectStore(storeName);
      const countRequest = objectStore.count();
      countRequest.onsuccess = () => {
        resolve(countRequest.result);
      };
      countRequest.onerror = () => {
        reject(countRequest.error);
      };
    });
  }
  addRecords(
    objectStoreName: string,
    records: Asset[] | Category[]
  ): Promise<boolean> {
    console.info('Started Transaction To Add New Records');
    let id = this.loaderService.show(
      { message: 'Lokale Daten werden synchronisiert...' },
      LoaderState.TransactionStarted
    );

    return new Promise(async (resolve, reject) => {
      if (this.db) {
        const transaction = this.db.transaction([objectStoreName], 'readwrite');
        const store = transaction.objectStore(objectStoreName);

        let hasError = false;

        transaction.oncomplete = async () => {
          if (objectStoreName !== 'categories') {
            await this.addCategoryUpdatedDate(records as Asset[], id);
          }
          this.loaderService.hide(id);
          resolve(true);
          console.info(`Transaction completed.`);
        };

        transaction.onerror = (event) => {
          const request = event.target as IDBRequest;
          if (!hasError) {
            console.error('Transaction failed:', request.error);
            this.loaderService.hide(id);
          }
          reject(request.error);
        };

        for (let record of records) {
          if (objectStoreName === 'categories') {
            record = { ...record, category_last_updated: new Date() };
          }
          try {
            const recordExist = await new Promise((resolve, reject) => {
              const recordExistReq = store.get(record.id);
              recordExistReq.onsuccess = (e) => {
                resolve((e.target as IDBRequest).result);
              };
              recordExistReq.onerror = (e) => {
                reject(e);
              };
            });

            if (recordExist) {
              console.log('Record already exists:');
              const lokalAndNewIdent = compareCommonPropertyValues(
                { ...recordExist },
                { ...record }
              );
              if (lokalAndNewIdent) {
                console.error('DoubleEntryError: Record Already Exist Locally');
                this.loaderService.hide(id);
                resolve(false);
                return;
              } else {
                console.info(
                  'Not Identict: Local Record and New Record Not Identical, updating...'
                );
                store.delete(record.id);
              }
            }
            const request = store.add(record);
            request.onerror = (event: Event) => {
              hasError = true;
              const request = event.target as IDBRequest;
              console.error(
                'Error adding record:',
                (request.error as Error).message
              );
              this.loaderService.hide(id);
              transaction.abort();
              reject(request.error);
            };
          } catch (error) {
            hasError = true;
            console.error('Error checking for existing record:', error);
            transaction.abort();
            this.loaderService.hide(id);
            reject(error);
          }
        }
      } else {
        reject(new Error('Database not available'));
      }
    });
  }
  async addCategoryUpdatedDate(
    records: Asset[],
    loaderId: string
  ): Promise<boolean> {
    return new Promise(async (resolve, reject) => {
      if (this.db) {
        if (records.length > 0) {
          let hasError = false;
          const updateStoreTransaction = this.db.transaction(
            ['category_last_updated'],
            'readwrite'
          );
          updateStoreTransaction.onerror = (event) => {
            const request = event.target as IDBRequest;
            if (!hasError) {
              console.error('Transaction failed:', request.error);
              this.loaderService.hide(loaderId);
            }
            reject(request.error);
          };
          const updateStore = updateStoreTransaction.objectStore(
            'category_last_updated'
          );
          try {
            const recordExist = await new Promise((resolve, reject) => {
              const recordExistReq = updateStore
                .index('category_id')
                .get(Number((records as Asset[])[0].category));
              recordExistReq.onsuccess = (e) => {
                resolve((e.target as IDBRequest).result);
              };
              recordExistReq.onerror = (e) => {
                reject(e);
              };
            });

            if (recordExist) {
              Object.assign(recordExist, {
                ...recordExist,
                last_updated: new Date(),
              });
              const updateRequest = updateStore.put(recordExist);
              updateRequest.onsuccess = () => resolve(true);
              updateRequest.onerror = (event: Event) => {
                hasError = true;
                const request = event.target as IDBRequest;
                console.error(
                  'Error adding record:',
                  (request.error as Error).message
                );
                updateStoreTransaction.abort();
                this.loaderService.hide(loaderId);
                reject(request.error);
              };
            } else {
              const addRecordReq = updateStore.add({
                category_id: (records as Asset[])[0].category,
                last_updated: new Date(),
              });
              addRecordReq.onsuccess = () => resolve(true);
              addRecordReq.onerror = (event: Event) => {
                hasError = true;
                const request = event.target as IDBRequest;
                console.error(
                  'Error adding record:',
                  (request.error as Error).message
                );
                this.loaderService.hide(loaderId);
                updateStoreTransaction.abort();
                reject(request.error);
              };
            }
          } catch (error) {
            hasError = true;
            console.error('Error checking for existing record:', error);
            this.loaderService.hide(loaderId);
            updateStoreTransaction.abort();
            reject(error);
          }
        }
      } else {
        reject(new Error('Database not available'));
      }
    });
  }
  async getCategoryLastUpdatedDate(categoryId: number): Promise<Date | null> {
    return new Promise((resolve, reject) => {
      if (this.db) {
        const transaction = this.db.transaction(['category_last_updated']);
        const objectStore = transaction.objectStore('category_last_updated');
        const index = objectStore.index('category_id');
        const request = index.get(Number(categoryId));
        request.onsuccess = (event: Event) => {
          const record = (event.target as IDBRequest).result;
          if (record) {
            resolve(record.last_updated);
          } else {
            resolve(null);
          }
        };
        request.onerror = (event: Event) => {
          reject((event.target as IDBRequest).error);
        };
      } else {
        reject(new Error('Database not available'));
      }
    });
  }
  async getLastUpdatedRecords(): Promise<
    Array<{ category_id: number; id: number; last_updated: Date }>
  > {
    return new Promise((resolve, reject) => {
      if (this.db) {
        const transaction = this.db.transaction(['category_last_updated']);
        const objectStore = transaction.objectStore('category_last_updated');
        const request = objectStore.getAll();
        request.onsuccess = (event: Event) => {
          const records = (event.target as IDBRequest).result;
          resolve(records);
        };
        request.onerror = (event: Event) => {
          reject((event.target as IDBRequest).error);
        };
      } else {
        reject(new Error('Database not available'));
      }
    });
  }
  async getAssetCountByCategory(
    category: number,
    childCategory?: number
  ): Promise<number> {
    return new Promise<number>((resolve, reject) => {
      if (this.db) {
        const transaction = this.db.transaction(['assets']);
        const objectStore = transaction.objectStore('assets');

        let countRequest: IDBRequest;

        if (childCategory !== undefined) {
          const index = objectStore.index('category_childCategory');
          const keyRange = IDBKeyRange.only([
            Number(category),
            Number(childCategory),
          ]);
          countRequest = index.count(keyRange);
        } else {
          const index = objectStore.index('category');
          countRequest = index.count(Number(category));
        }

        countRequest.onsuccess = (event: Event) => {
          resolve((event.target as IDBRequest).result);
        };

        countRequest.onerror = (event) => {
          reject((event.target as IDBRequest).error);
        };
      } else {
        reject(new Error('Database not available'));
      }
    });
  }
  async getDataFromIndexDbByCategory(
    category: number,
    chunkSize = 500,
    startIndex = 0,
    getAll?: boolean
  ): Promise<Asset[]> {
    console.log('Get local assets');
    let id = this.loaderService.show(
      { message: 'Lokale Daten werden abgerufen...' },
      LoaderState.LocalLoading
    );
    return new Promise<Asset[]>(async (resolve, reject) => {
      if (this.db) {
        try {
          const count = await this.getAssetCountByCategory(category);
          const transaction = this.db.transaction(['assets']);
          const objectStore = transaction.objectStore('assets');
          const index = objectStore.index('category');
          if (count > chunkSize && !getAll) {
            console.log('DATA TOO BIG, FETCHIN IN CHUNKS');
            const range = IDBKeyRange.only(Number(category));
            const request = index.openCursor(range);
            let results: Asset[] = [];
            let fetchedCount = 0;
            request.onsuccess = (event) => {
              const cursor = (event.target as IDBRequest).result;
              if (cursor) {
                //Check if its the first fetch or first chunk already loaded
                //if its not first fetch, then advance to the location where
                //fetchin data stopped by last iteration
                if (startIndex > 0) {
                  if (startIndex < count) {
                    cursor.advance(startIndex);
                  } else {
                    //this avoid jumping out of bound for cursor
                    cursor.advance(count - 1);
                  }
                  // Move the cursor to the starting index
                  startIndex = 0; // Reset startIndex for subsequent records
                } else if (fetchedCount < chunkSize) {
                  // this section cumulate data till chunkSize being reached
                  results.push(cursor.value);
                  fetchedCount++;
                  cursor.continue();
                } else {
                  this.loaderService.hide(id);
                  resolve(results);
                }
              } else {
                // Reached the end of the cursor
                this.loaderService.hide(id);
                resolve(results);
              }
            };
            request.onerror = (event) => {
              this.loaderService.hide(id);
              reject((event.target as IDBRequest).error);
            };
          } else {
            const getAllRequest = index.getAll(Number(category));
            getAllRequest.onsuccess = (event: Event) => {
              this.loaderService.hide(id);
              resolve((event.target as IDBRequest).result);
            };
            getAllRequest.onerror = (event) => {
              this.loaderService.hide(id);
              reject((event.target as IDBRequest).error);
            };
          }
        } catch (error) {
          this.loaderService.hide(id);
          reject(error);
        }
      } else {
        reject(new Error('Database not available'));
      }
    });
  }

  async getAssetsByCategoryAndChildCategorie(
    categoryId: number,
    childCategoryId: number
  ): Promise<Asset[]> {
    return new Promise((resolve, reject) => {
      if (this.db) {
        const transaction = this.db.transaction(['assets'], 'readonly');
        const objectStore = transaction.objectStore('assets');
        const index = objectStore.index('category_childCategory');
        const range = IDBKeyRange.only([
          Number(categoryId),
          Number(childCategoryId),
        ]);

        const request = index.openCursor(range);
        const matchingAssets: Asset[] = [];
        request.onsuccess = (event: Event) => {
          const cursor = (event.target as IDBRequest<IDBCursorWithValue | null>)
            .result;
          if (cursor) {
            matchingAssets.push(cursor.value);
            cursor.continue();
          } else {
            resolve(matchingAssets);
          }
        };

        request.onerror = (event: Event) => {
          console.error(
            'Error opening cursor:',
            (event.target as IDBRequest).error
          );
          reject((event.target as IDBRequest).error);
        };
      } else {
        reject(new Error('Database not available'));
      }
    });
  }
  /**
   *
   * @param category catrogry of asset to update
   * @returns resolved Promise with boolean state as Value
   */
  async deleteAssetsByCategory(category: number): Promise<boolean> {
    let id = this.loaderService.show(
      { message: 'Lokale Daten werden synchronisiert...' },
      LoaderState.TransactionStarted
    );
    return new Promise((resolve, reject) => {
      if (this.db) {
        const transaction = this.db.transaction(['assets'], 'readwrite');
        const objectStore = transaction.objectStore('assets');
        const index = objectStore.index('category');
        const cursorRequest = index.openCursor(Number(category));
        let updated = false;
        cursorRequest.onsuccess = (event) => {
          const cursor = (event.target as IDBRequest).result;
          if (cursor) {
            objectStore.delete(cursor.primaryKey);
            cursor.continue();
          } else {
            console.log('Deleted Old Data succefully!');
            updated = true;
            this.loaderService.hide(id);
            resolve(updated);
            transaction.commit();
          }
        };
        cursorRequest.onerror = (event) => {
          const error = (event.target as IDBRequest).error;
          console.error('Error opening cursor:', error);
          this.loaderService.hide(id);
          reject(new Error('Error opening cursor: ' + error?.message));
        };
      } else {
        reject(new Error('Database not available'));
      }
    });
  }
  getAllCategoriesRecordsLocally(): Promise<Category[]> {
    return new Promise((resolve, reject) => {
      if (this.db) {
        const transaction = this.db.transaction(['categories'], 'readonly');
        const store = transaction.objectStore('categories');
        const request = store.getAll();
        request.onsuccess = (event: Event) => {
          const request = event.target as IDBRequest;
          resolve(request.result);
        };
        request.onerror = (event: Event) => {
          const request = event.target as IDBRequest;
          reject(request.error);
        };
      } else {
        reject(new Error('Database not available'));
      }
    });
  }

  async deleteDatabase(dbName: string): Promise<boolean> {
    return new Promise<boolean>((resolve, reject) => {
      const request = window.indexedDB.deleteDatabase(dbName);

      request.onsuccess = () => {
        console.log(`Database "${dbName}" deleted successfully.`);
        resolve(true);
      };

      request.onerror = (event: Event) => {
        const target = event.target as IDBRequest;
        console.error(`Error deleting database "${dbName}":`, target.error);
        reject(target.error);
      };

      request.onblocked = () => {
        console.warn(
          `Database "${dbName}" is blocked and cannot be deleted right now.`
        );
        reject(new Error(`Database "${dbName}" is blocked.`));
      };
    });
  }
  /**
   * Closes the current database connection instance and then attempts to delete the database.
   * Waits for the current connection to close before initiating deletion.
   * Rejects if deletion is blocked (indicating other open connections).
   * Does NOT set this.db to null after closing.
   *
   * @param dbName The name of the database to delete.
   * @returns Promise<true> if deletion is successful.
   * @rejects {Error} If closing the connection fails, deletion fails, or deletion is blocked.
   */
  async deleteDatabaseByName(dbName: string): Promise<boolean> {
    // --- Step 1: Close the current connection instance ---
    if (this.db) {
      console.log(
        `Attempting to close the current connection instance to "${dbName}"...`
      );
      // Capture the reference

      const closePromise = new Promise<void>((resolve, reject) => {
        // IMPORTANT: Assign onclose *before* calling close()
        if (this.db) {
          this.db.onclose = () => {
            console.log(
              `Current connection instance to "${dbName}" successfully closed.`
            );
            // Clean up the handler to avoid memory leaks if the instance is reused (though unlikely here)
            // this.db!.onclose = null;
            resolve();
          };

          // Handle potential errors on the connection *during* the close process
          // Note: 'onerror' on IDBDatabase is usually for unhandled transaction errors,
          // but it's worth having a safety net. 'onabort' might also be relevant
          // depending on how connections are managed elsewhere.
          this.db.onerror = (event) => {
            console.error(
              `Error event on database connection during close for "${dbName}":`,
              event
            );
            reject(
              new Error(
                `Database connection error during close: ${
                  event.target || 'Unknown error'
                }`
              )
            );
          };

          try {
            console.log(`Requesting close for current connection instance...`);
            // Initiate the close operation
            this.db?.close();
            // this.db = null;
            resolve();
            console.log(
              `Close requested for current connection instance to "${dbName}". Waiting for 'onclose' event...`
            );

            // Note: The close operation is asynchronous. The 'onclose' handler above will trigger when it's done.
          } catch (error) {
            // Catch potential synchronous errors from calling close() itself (rare)
            console.error(
              `Synchronous error calling db.close() for "${dbName}":`,
              error
            );
            reject(error);
          }
        }
      });
      console.log('Passed closing');
      // Wait for the current connection instance to confirm it's closed
      try {
        console.log(`Waiting for current connection instance to close...`);
        await closePromise;
        // Important: At this point, `this.db` reference still exists but points to a closed DB.
        // We are explicitly *not* setting `this.db = null;` as requested.
        // Be aware that subsequent operations on this.db will likely fail unless it's reopened.
      } catch (closeError) {
        console.error(
          `Failed to close the current connection instance for "${dbName}". Aborting deletion.`,
          closeError
        );
        // Rethrow or handle as appropriate for your application logic
        throw closeError; // Propagate the error
      }
    } else {
      console.warn(
        `No active connection instance (this.db) found for "${dbName}". Proceeding directly to delete attempt.`
      );
      // If there's no 'this.db', we can skip closing it and go straight to deletion.
    }

    // --- Step 2: Attempt to delete the database ---
    console.log(`Attempting to delete database "${dbName}"...`);
    return new Promise<boolean>((resolve, reject) => {
      console.log(this.db);
      const request = window.indexedDB.deleteDatabase('assets_db');

      request.onsuccess = () => {
        console.log(`Database "${dbName}" deleted successfully.`);
        this.dbStatusSubject.next({
          ready: 'DELETED',
          error: null,
          message: `Database "${dbName}" deleted successfully.`,
        });
        return resolve(true);
      };

      request.onerror = (event: Event) => {
        const target = event.target as IDBRequest;
        console.error(`Error deleting database "${dbName}":`, target.error);
        reject(
          target.error ||
            new Error(`Unknown error deleting database "${dbName}"`)
        ); // Provide default
      };

      // This is crucial. It means other connections (e.g., in other tabs) are still open.
      request.onblocked = () => {
        // Added event parameter for potential debugging
        console.warn(
          `Database "${dbName}" deletion is blocked. Please close ALL other connections/tabs using this database.`
        );
        // It's standard practice to reject here, as the deletion cannot proceed.
        reject(
          new Error(
            `Database "${dbName}" deletion blocked. Other connections may still be open.`
          )
        );
      };
    });
  }
  async clearObjectStore(storeName: string): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      if (!this.db) {
        reject(new Error('Database not available'));
      } else {
        const transaction = this.db.transaction([storeName], 'readwrite');
        const objectStore = transaction.objectStore(storeName);
        const request = objectStore.clear();

        request.onsuccess = () => {
          console.log(`Object store "${storeName}" cleared successfully.`);
          resolve();
        };

        request.onerror = (event) => {
          const target = event.target as IDBRequest;
          console.error(
            `Error clearing object store "${storeName}":`,
            target.error
          );
          reject(target.error);
        };
      }
    });
  }
  //-------------------------------------------------------FOLLOWING NOT USED YET---------------------------------------------------

  // updateRecord(record: unknown): Promise<unknown> {
  //   return new Promise((resolve, reject) => {
  //     const transaction = this.db.transaction('object-store-name', 'readwrite');
  //     const store = transaction.objectStore('object-store-name');
  //     const request = store.put(record);

  //     request.onsuccess = (event: Event) => {
  //       const request = event.target as IDBRequest;
  //       resolve(request.result);
  //     };

  //     request.onerror = (event: Event) => {
  //       const request = event.target as IDBRequest;
  //       reject(request.error);
  //     };
  //   });
  // }

  // getAllRecords(): Promise<unknown[]> {
  //   return new Promise((resolve, reject) => {
  //     const transaction = this.db.transaction('object-store-name', 'readonly');
  //     const store = transaction.objectStore('object-store-name');
  //     const request = store.openCursor();
  //     const records: unknown[] = [];

  //     request.onsuccess = (event: Event) => {
  //       const cursor = (event.target as IDBRequest).result;
  //       if (cursor) {
  //         records.push(cursor.value);
  //         cursor.continue();
  //       } else {
  //         resolve(records);
  //       }
  //     };

  //     request.onerror = (event: Event) => {
  //       const request = event.target as IDBRequest;
  //       reject(request.error);
  //     };
  //   });
  // }
}
