// db-init.resolver.ts (or any suitable file name/location)

import { inject } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  ResolveFn,
  RouterStateSnapshot,
} from '@angular/router';
import { DatabaseService } from './services/database.service';

// This resolver ensures the DB is initialized. It resolves to true/false indicating success.
export const databaseInitializedResolver: ResolveFn<boolean> = (
  route: ActivatedRouteSnapshot,
  state: RouterStateSnapshot
): Promise<boolean> => {
  const dbService = inject(DatabaseService);
  return dbService.initDb();
};
