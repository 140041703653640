import { Component, OnDestroy, OnInit } from '@angular/core';
import {
  Router,
  RouterLink,
  RouterLinkActive,
  RouterOutlet,
} from '@angular/router';
import { AssetService } from './services/asset.service';
import { CommonModule } from '@angular/common';
import { ClarityModule } from '@clr/angular';
import { CategoryService } from './services/category.service';
import { Category } from './interfaces/category';
import { filter, Subject, takeUntil } from 'rxjs';
import { ClarityIcons, cogIcon } from '@cds/core/icon';
import {
  EventMessage,
  EventType,
  InteractionStatus,
} from '@azure/msal-browser';
import { MsalBroadcastService, MsalService } from '@azure/msal-angular';
import { AnalyticsService } from './services/analytics.service';
import { FormsModule } from '@angular/forms';
import {
  DatabaseService,
  DbInitializationStatus,
} from './services/database.service';
import { LoaderService, LoaderState } from './services/loader.service';
import { isDateOlderThanLimit } from './services/useables';

@Component({
  selector: 'app-root',
  standalone: true,
  imports: [
    RouterOutlet,
    RouterLink,
    RouterLinkActive,
    ClarityModule,
    CommonModule,
    FormsModule,
  ],
  templateUrl: './app.component.html',
  styleUrl: './app.component.css',
})
export class AppComponent implements OnInit, OnDestroy {
  callCollapse($event: Event) {
    const target = $event.target as HTMLElement;
    if (
      (target.getAttribute('type') &&
        target.getAttribute('type') === 'button' &&
        target.classList.contains('nav-trigger')) ||
      target.classList.contains('nav-trigger-icon')
    ) {
      this.isCollapsed = !this.isCollapsed;
      this.containerClass = !this.isCollapsed
        ? 'nav-content'
        : 'nav-content-closed';
    }
    if (
      (target.classList.contains('nav-group-text') ||
        target.classList.contains('nav-icon')) &&
      this.isCollapsed
    ) {
      this.isCollapsed = false;
      this.containerClass = 'nav-content';
    }
  }
  isLoading = false;
  loadingMessage = '';
  isOnline = true;
  countedAssets = 0;
  categories: Category[] = [];
  isIframe = false;
  loginDisplay = false;
  autoUpdateOn: boolean = true;
  updatingDbOngoing: boolean = false;
  private readonly _destroying$ = new Subject<void>();
  dbStatus: DbInitializationStatus | null = null;
  isCollapsed: boolean = false;
  containerClass: string = 'nav-content';
  constructor(
    private assetService: AssetService,
    private categoryService: CategoryService,
    private authService: MsalService,
    private msalBroadcastService: MsalBroadcastService,
    private analyticsService: AnalyticsService,
    private router: Router,
    private dbService: DatabaseService,
    private loaderService: LoaderService
  ) {
    ClarityIcons.addIcons(cogIcon);
  }
  private destroy$ = new Subject<void>();
  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  async ngOnInit() {
    this.analyticsService.trackEvent('', '', '');
    window.addEventListener('online', () => (this.isOnline = true));
    window.addEventListener('offline', () => (this.isOnline = false));

    const isTrue = localStorage.getItem('onceTrue');
    if (isTrue === null) {
      localStorage.setItem('onceTrue', 'true');
    }
    this.loaderService.loadingState.subscribe((state) => {
      this.loadingMessage = state.loadingData.message;
      this.isLoading = state.state !== LoaderState.NotLoading;
      this.updatingDbOngoing = state.state === 'TransactionStarted';
    });

    /**Next lines assures that autoUpdate State been centerally assigned,
     * to avoid value assigning clash between components
     * and always keep user update choice sustained betweens render laps
     */
    if (!localStorage.getItem('updateState')) {
      localStorage.setItem('updateState', String(this.autoUpdateOn));
    }
    this.autoUpdateOn =
      localStorage.getItem('updateState') &&
      localStorage.getItem('updateState') === 'false'
        ? false
        : true;
    this.assetService.setDataSynchronizingUpdateState(
      localStorage.getItem('updateState') &&
        localStorage.getItem('updateState') === 'false'
        ? false
        : true
    );

    this.authService.handleRedirectObservable().subscribe(async (next) => {
      if (next) {
        // Es wurde eine Anmeldung durchgeführt, der User soll nun auf die Startseite geleitet werden. Damit alle Einstellungen übernohmen werden
        // soll anschließend die Seite einmal neu geladen werden.
        await this.router.navigateByUrl('/app/home');
        window.location.reload();
      }
    });

    this.isIframe = window !== window.parent && !window.opener; // Remove this line to use Angular Universal
    this.setLoginDisplay();

    this.authService.instance.enableAccountStorageEvents(); // Optional - This will enable ACCOUNT_ADDED and ACCOUNT_REMOVED events emitted when a user logs in or out of another tab or window
    this.msalBroadcastService.msalSubject$
      .pipe(
        filter(
          (msg: EventMessage) =>
            msg.eventType === EventType.ACCOUNT_ADDED ||
            msg.eventType === EventType.ACCOUNT_REMOVED
        )
      )
      .subscribe(() => {
        if (this.authService.instance.getAllAccounts().length === 0) {
          window.location.pathname = '/';
        } else {
          this.setLoginDisplay();
        }
      });

    this.msalBroadcastService.inProgress$
      .pipe(
        filter(
          (status: InteractionStatus) => status === InteractionStatus.None
        ),
        takeUntil(this._destroying$)
      )
      .subscribe(() => {
        this.setLoginDisplay();
        this.checkAndSetActiveAccount();
      });

    setTimeout(async () => {
      this.categories = (await this.categoryService.getCategories()).filter(
        (e) => e.tool_id === 1 || e.tool_id === null
      );
    }, 100);
    await this.dbService.getDatabase();
    this.dbService.dbStatus$
      .pipe(takeUntil(this.destroy$))
      .subscribe(async (status: DbInitializationStatus | null) => {
        console.info('Received DB Status Update:', status?.ready);
        this.dbStatus = status;
        if (status?.ready === 'READY') {
          console.log('Database is ready!');
          // setTimeout(async () => {
          //   if (
          //     !(
          //       (await this.dbService.getAllCategoriesRecordsLocally()).length >
          //       0
          //     ) &&
          //     this.categories.length > 0
          //   ) {
          //     this.dbService.addRecords('categories', this.categories);
          //   }
          // }, 1500);

          const localDbCreationDate = new Date(
            (await this.dbService.getMetaData()).createdAt
          );
          const dateExceededLimit = isDateOlderThanLimit(
            localDbCreationDate.toISOString() ?? '',
            30
          );

          await this.authService.instance.initialize();
          if (
            dateExceededLimit &&
            this.authService.instance.getActiveAccount()
          ) {
            console.error('User is logged in');

            const deletedDb = await this.dbService.deleteDatabaseByName(
              'assets_db'
            );
            console.error('Deleted DB', deletedDb);
          }
        } else if (status?.ready === 'DELETED') {
          localStorage.setItem('signedOut', `zeap ${Math.random()}`);
          this.authService.instance.logoutRedirect();
          localStorage.setItem('one', '2');
        } else if (status?.error) {
          console.error(
            'AppComponent: Database initialization error detected!',
            status.error
          );
        }
      });
  }

  toggleAutoUpdate() {
    this.autoUpdateOn = !this.autoUpdateOn;
    localStorage.setItem('updateState', String(this.autoUpdateOn));
    this.assetService.setDataSynchronizingUpdateState(this.autoUpdateOn);
  }

  setLoginDisplay() {
    this.loginDisplay = this.authService.instance.getAllAccounts().length > 0;
    if (!this.loginDisplay) {
      this.router.navigateByUrl('/login');
    }
  }

  checkAndSetActiveAccount() {
    let activeAccount = this.authService.instance.getActiveAccount();

    if (
      !activeAccount &&
      this.authService.instance.getAllAccounts().length > 0
    ) {
      let accounts = this.authService.instance.getAllAccounts();
      this.authService.instance.setActiveAccount(accounts[0]);
    }
  }

  logout(popup?: boolean) {
    localStorage.removeItem('updateState');
    if (popup) {
      this.authService.logoutPopup({
        mainWindowRedirectUri: '/',
      });
    } else {
      this.authService.logoutRedirect();
    }
  }
}
